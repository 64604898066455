<template>
  <div>
    <Breadcrumbs title="User details" main="Users"/>
    <div class="container-fluid">
      <b-row>
        <b-col cols="12" md="6" xl="4">
          <div class="card">
            <div class="card-body" v-if="user != null">
              <ul class="crm-activity">
                <li class="text-center">
                  <img src="../../assets/images/dashboard/user.png" alt="Profile Image" class="align-self-center img-100 rounded-circle">
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">User ID</h6>
                    <ul class="dates">
                      <li class="digits">{{user.id}}</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">NIC / Passport</h6>
                    <ul class="dates">
                      <li class="digits">{{user.nic}}</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Username</h6>
                    <ul class="dates">
                      <li class="digits">{{user.username}}</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Email</h6>
                    <ul class="dates">
                      <li class="digits">{{user.email || "N/A"}}</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Phone (Home)</h6>
                    <ul class="dates">
                      <li class="digits">{{user.phone_home || "N/A"}}</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Phone (Mobile)</h6>
                    <ul class="dates">
                      <li class="digits">{{user.phone_mobile || "N/A"}}</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Date Of Birth (DOB)</h6>
                    <ul class="dates">
                      <li class="digits">{{user.dob || "Unknown"}}</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Gender</h6>
                    <ul class="dates">
                      <li class="digits">{{ user.gender ? 'Female' : 'Male' }}</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Address</h6>
                    <ul class="dates">
                      <li class="digits">
                        <div>{{user.address_line_1}}</div>
                        <div>{{user.address_line_2}}</div>
                        <div>{{user.address_line_2}}</div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Status</h6>
                    <ul class="dates">
                      <li v-if="user.status === 0" class="digits txt-success">Active</li>
                      <li v-else-if="user.status === 1" class="digits text-warning">Pending</li>
                      <li v-else-if="user.status === 2" class="digits text-danger">Suspended</li>
                      <li v-else class="digits">Unknown</li>
                    </ul>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="4">
          <div class="card">
            <div class="card-body">
              <ul class="crm-activity">
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Roles</h6>
                    <ul v-if="user.roles.length" class="dates">
                      <li class="digits" v-for="(role, index) in user.roles" :key="index">
                        {{role.name}}
                      </li>
                    </ul>
                    <ul v-else class="dates">
                      <li class="digits">N/A</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Institutes</h6>
                    <ul v-if="user.institutes.length" class="dates">
                      <li class="digits" v-for="(institute, index) in user.institutes" :key="index">
                        {{institute.name}}
                      </li>
                    </ul>
                    <ul v-else class="dates">
                      <li class="digits">N/A</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="align-self-center media-body">
                    <h6 class="mt-0">Subjects</h6>
                    <ul class="dates" v-if="user.subjects.length">
                      <li class="digits" v-for="(subject, index) in user.subjects" :key="index">
                        {{subject.name}}
                      </li>
                    </ul>
                    <ul v-else class="dates">
                      <li class="digits">N/A</li>
                    </ul>
                  </div>
                </li>

              </ul>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="align-self-center media-body">
                <h6 class="mt-0">Classes</h6>
                <ul v-if="user.class_details.length" class="dates">
                  <li v-for="(class_detail, index) in user.class_details" :key="index" class="digits">{{class_detail.name}}</li>
                </ul>
                <ul v-else class="dates">
                  <li class="digits">N/A</li>
                </ul>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import API from '@/services/crud'
export default {
  name: "users-show",
  mounted() {
    this.fetchUser()
  },
  data() {
    return {
      user: null,
      loaded: false
    }
  },
  methods: {
    async fetchUser() {
      this.loaded = false
      await API.show('/api/backend/pages/users', this.$route.params.userId)
          .then((result)=>{
            this.user = result.data.data
            this.loaded = true
          }).catch((error) => {
            console.log(error)
            this.$toasted.error("User data could not be retrieved.")
            this.$router.push({ name: 'users-index' })
          })
    }
  }
}
</script>

<style scoped>

</style>
